
.border_bottom {
    border-bottom: 0.5px solid #bababa;
}

.border_all {
    border: 0.5px solid #bababa;
    height: 75vh;
    overflow-y: scroll;
}

.border_all::-webkit-scrollbar {
    display: none;
}

.border_right {
    border-right: 0.5px solid #bababa;
    height: 89vh;
    /* height: 100%; */
    overflow-y: scroll;
}

.border_right::-webkit-scrollbar {
    display: none;
}

.accordion-button:not(.collapsed) {
    background-color: #18498b !important;
    color: #ffffff !important;
    box-shadow: none !important
}

.accordion-button {
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #18498b !important;
}

.accordion-button::after {
    color: #fff !important;
}

.cursor {
    cursor: pointer;
}

.menu_background {
    background-color: #18498b;
    color: #ffffff;
    font-weight: 600;
}

.heading_name {
    font-size: 18px;
    font-weight: 500;
}

.subheading_name {
    font-size: 13px;
    font-weight: 500;
}

.font {
    background-color: #ffffff;
    font-weight: 500;
}

.font:hover {
    background-color: #bed4eb;
}

.icon_size {
    font-size: 25px;
    color: #252525
}

.menu_font {
    font-size: 20px;
}

.title_font {
    font-size: 20px;
    font-weight: 500;
    color: #18498b;
}

.time_icon {
    font-size: 15px;
    font-weight: 500;
    color: #18498b;
}

.align_profile {
    position: absolute;
    right: 15%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.16);
    width: 10%;
    z-index: 99999;
    top: 8%;
}

.select_align {
    border: none;
    outline: none;
    background-color: #eeeeee;
    border-radius: 10px;
    cursor: pointer;
    color: #18498b;
    font-weight: 600;
}

.index_border {
    background-color: #18498b;
    color: #ffffff;
    font-weight: 600;
    border-radius: 10px;
    padding: 8px 15px;
    font-size: 20px;
    cursor: pointer;
}

.proceum_width {
    width: 50%;
}

.book_back {
    background-color: #18498b;
    color: #fff;
    padding: 0px 2px 2px 3px;
    border-radius: 5px;
    margin-right: 4px;
}

.side_header {
    background-color: #f1f1f1;
}

.side_font {
    font-size: 18px;
    color: #000;
    font-weight: 600;   
}

.one {
    background: rgb(51, 119, 255);
    color: rgb(255, 255, 255);
    border-radius: 50%;
    font-weight: 600;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot_border {
    border: 1px dotted;
    border-radius: 10px;
}

.clarif {
    font-size: 13px;
    font-weight: 400;
    color: #000;
}

.yt {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.clarif1 {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.sf {
    font-size: 16px;
    color: black;
}

.com {
    color: #4c88ff;
    font-weight: 500;
}

.sk1 {
    text-decoration: underline;
    text-decoration-color: red;
}


.note {
    border: 1px solid #dfdcdc;
    border-radius: 10px;
    padding: 15px;
}

.pal {
    padding: 8px;
    border-radius: 27%;
    width: 2%;
    cursor: pointer;
    background-color: rgba(6, 194, 112, 0.8);
}

.fixed {    
        position: fixed;
        bottom: 0;
        right: 25px;
        z-index: 99999;
}

.fix {
    background: #2e3a53;
    color: white;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -4px 15px rgba(65, 68, 73, .18);
    height: 40px;
    width: 200px;
    z-index: 9999999;
}

.select_center {
    border-radius: 50%;
    border: 1px solid #18498B;
    padding: 1px 10px;
}

.select_center_correct {
    border-radius: 50%;
    border: 1px solid #18498B;
    padding: 0px 8px;
    background-color: #18498B;
}

.correct_circle {
    border: 1px solid #18498B;
    border-radius: 50%;
    padding: 3px;
}

.button1 {
    background-color:#18498b;
    padding:10px 9px 10px 0px;
    border-radius:10px;
    cursor:"pointer";
    opacity: 0.4;
}

.button2 {
    background-color:#18498b; 
    padding:10px 0px 10px 8px; 
    border-radius:10px; 
    cursor:pointer;
}

.button3 {
    background-color: #008C50;
    color: #fff;
    width: 20%;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.arrow_button {
    border:1px solid #fff;
    padding: 13px;
    border-radius: 10px;
    background-color: #18498b;
    color:#fff;
}

.que_font1 {
    font-size: 15px;
    background-color: #18498b;
    padding: 10px;
    border-radius: 8px;
    color: #fff;
}

.que_font2 {
    font-size: 15px;
    background-color: #F0F5FB;
    padding: 10px;
    border-radius: 8px;
    color: #000;
}

.height_fea {
    height: 80vh;
    overflow-y: scroll;
}

.height_fea::-webkit-scrollbar {
    display: none;
}

.video_heading {
    border: 1px solid #18498b;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 18px;
    color: #18498b;
    font-weight: 500;
    cursor: pointer;
}

.main_width {
    width: 100%;
}

.add_content {
    width: 50%;
}

@media screen and (min-width:801px) {    /* 67% screen resolution */

    .border_right {
        height: 91vh !important;
    }

    .border_all {
        height: 83.7vh !important;
    }
}

 @media screen and (max-width:1720px) {    /* 75% screen resolution */

    .border_right {
        height: 90vh !important;
    }

    .border_all {
        height: 81.6vh !important;
    }
}

@media screen and (max-width:1620px) {      /* 80% screen resolution */

    .border_right {
        height: 89.3vh !important;
    }

    .border_all {
        height: 80.4vh !important;
    }
}

@media screen and (max-width:1500px) {      /* 90% screen resolution */

    .border_right {
        height: 88vh !important;
    }

    .border_all {
        height: 74.8vh !important;
    }
}

@media screen and (max-width:1300px) {      /* 100% screen resolution */

    .border_right {
        height: 86.6vh !important;
    }

    .border_all {
        height: 72vh !important;
    }
}